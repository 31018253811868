// import React, { useState } from 'react';
// import { 
//   Box, 
//   Container,
//   Typography,
//   TextField,
//   Button,
//   Paper,
//   Divider,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   CircularProgress,
//   Alert,
//   Card,
//   CardContent,
//   Grid,
//   IconButton,
//   Tooltip
// } from '@mui/material';
// import {
//   Lock as LockIcon,
//   Language as LanguageIcon,
//   Upload as UploadIcon,
//   Google as GoogleIcon,
//   PictureAsPdf as PdfIcon,
//   ContentPaste as PasteIcon,
//   Link as LinkIcon,
//   Info as InfoIcon
// } from '@mui/icons-material';

// const DocumentUploadPage = () => {
//   const [url, setUrl] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');

//   const handleUrlSubmit = async (e) => {
//     e.preventDefault();
//     if (!url) {
//       setError('Please enter a valid URL');
//       return;
//     }

//     setIsLoading(true);
//     setError('');
//     setSuccess('');

//     try {
//       // Mock API call - replace with actual implementation
//       await new Promise(resolve => setTimeout(resolve, 2000));
//       setSuccess('URL has been successfully submitted for crawling!');
//       setUrl('');
//     } catch (err) {
//       setError('Failed to process URL. Please try again.');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const PremiumFeature = ({ icon, title, description }) => (
//     <Card className="w-full mb-4 relative overflow-visible">
//       <CardContent className="flex items-center">
//         <Box className="mr-4">
//           {icon}
//         </Box>
//         <Box className="flex-grow">
//           <Typography variant="h6" className="flex items-center">
//             {title}
//             <LockIcon className="ml-2 text-gray-400" fontSize="small" />
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             {description}
//           </Typography>
//         </Box>
//       </CardContent>
//     </Card>
//   );

//   return (
//     <Container maxWidth="md" className="py-8">
//       {/* Free Feature Section */}
//       <Paper elevation={3} className="p-6 mb-8">
//         <Typography variant="h5" className="mb-4 flex items-center">
//           <LanguageIcon className="mr-2" />
//           Web Crawler
//           <Tooltip title="Enter any website URL and we'll automatically extract and process its content">
//             <IconButton size="small" className="ml-2">
//               <InfoIcon fontSize="small" />
//             </IconButton>
//           </Tooltip>
//         </Typography>
        
//         <form onSubmit={handleUrlSubmit} className="mb-4">
//           <TextField
//             fullWidth
//             variant="outlined"
//             placeholder="Enter website URL (e.g., https://example.com)"
//             value={url}
//             onChange={(e) => setUrl(e.target.value)}
//             disabled={isLoading}
//             InputProps={{
//               startAdornment: <LinkIcon className="mr-2 text-gray-400" />,
//             }}
//             className="mb-4"
//           />
          
//           <Button
//             variant="contained"
//             color="primary"
//             type="submit"
//             disabled={isLoading}
//             className="w-full"
//           >
//             {isLoading ? (
//               <CircularProgress size={24} className="mr-2" />
//             ) : (
//               'Start Crawling'
//             )}
//           </Button>
//         </form>

//         {error && (
//           <Alert severity="error" className="mb-4">
//             {error}
//           </Alert>
//         )}
        
//         {success && (
//           <Alert severity="success" className="mb-4">
//             {success}
//           </Alert>
//         )}
//       </Paper>

//       {/* Premium Features Section */}
//       <Box className="mb-6">
//         <Typography variant="h5" className="mb-4 flex items-center">
//           <LockIcon className="mr-2" />
//           Premium Features
//           <Tooltip title="Upgrade to access additional document upload methods">
//             <IconButton size="small" className="ml-2">
//               <InfoIcon fontSize="small" />
//             </IconButton>
//           </Tooltip>
//         </Typography>

//         <Grid container spacing={3}>
//           <Grid item xs={12}>
//             <PremiumFeature
//               icon={<PdfIcon fontSize="large" className="text-gray-400" />}
//               title="PDF Upload"
//               description="Upload and process PDF documents directly"
//             />
//           </Grid>
          
//           <Grid item xs={12}>
//             <PremiumFeature
//               icon={<GoogleIcon fontSize="large" className="text-gray-400" />}
//               title="Google Drive Integration"
//               description="Connect and import documents from Google Drive"
//             />
//           </Grid>
          
//           <Grid item xs={12}>
//             <PremiumFeature
//               icon={<PasteIcon fontSize="large" className="text-gray-400" />}
//               title="Direct Text Input"
//               description="Paste or type content directly for processing"
//             />
//           </Grid>
//         </Grid>

//         <Button
//           variant="outlined"
//           color="primary"
//           className="mt-4 w-full"
//           startIcon={<LockIcon />}
//         >
//           Upgrade to Premium
//         </Button>
//       </Box>
//     </Container>
//   );
// };

// export default DocumentUploadPage;


import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Tooltip,
  Grid,
  useTheme
} from '@mui/material';
import {
  Lock as LockIcon,
  Language as LanguageIcon,
  Google as GoogleIcon,
  PictureAsPdf as PdfIcon,
  ContentPaste as PasteIcon,
  Info as InfoIcon
} from '@mui/icons-material';

const DocumentUploadPage = () => {
  const theme = useTheme();
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleUrlSubmit = async (e) => {
    e.preventDefault();
    if (!url) {
      setError('Please enter a valid URL');
      return;
    }

    setIsLoading(true);
    setError('');
    setSuccess('');

    try {
      // Mock API call - replace with actual implementation
      await new Promise(resolve => setTimeout(resolve, 2000));
      setSuccess('URL has been successfully submitted for crawling!');
      setUrl('');
    } catch (err) {
      setError('Failed to process URL. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const PremiumFeatureCard = ({ icon, title, description }) => (
    <Card 
      elevation={0}
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        height: '100%',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: theme.shadows[2]
        }
      }}
    >
      <CardContent sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
          <Box 
            sx={{ 
              p: 1.5,
              borderRadius: 1,
              bgcolor: theme.palette.primary.light,
              color: theme.palette.primary.main,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {icon}
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle1" sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              fontWeight: 500,
              mb: 0.5
            }}>
              {title}
              <LockIcon sx={{ fontSize: 16, color: theme.palette.text.disabled }} />
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ maxWidth: 800, mx: 'auto' }}>
        {/* Free Feature Section */}
        <Card 
          elevation={0}
          sx={{ 
            mb: 4,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <CardContent sx={{ p: 4 }}>
            <Typography variant="h5" sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              mb: 3,
              fontWeight: 500
            }}>
              <LanguageIcon color="primary" />
              Web Crawler
              <Tooltip title="Enter any website URL and we'll automatically extract and process its content" arrow>
                <InfoIcon 
                  sx={{ 
                    fontSize: 18, 
                    color: theme.palette.text.disabled,
                    cursor: 'help'
                  }} 
                />
              </Tooltip>
            </Typography>

            <form onSubmit={handleUrlSubmit}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="https://example.com"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                disabled={isLoading}
                sx={{ mb: 2 }}
              />

              <Button
                variant="contained"
                fullWidth
                type="submit"
                disabled={isLoading}
                sx={{
                  py: 1.5,
                  textTransform: 'none',
                  fontWeight: 500
                }}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Start Crawling'
                )}
              </Button>
            </form>

            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}

            {success && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {success}
              </Alert>
            )}
          </CardContent>
        </Card>

        {/* Premium Features Section */}
        <Box>
          <Typography variant="h5" sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1,
            mb: 3,
            fontWeight: 500
          }}>
            <LockIcon color="primary" />
            Premium Features
            <Tooltip title="Upgrade to access additional document upload methods" arrow>
              <InfoIcon 
                sx={{ 
                  fontSize: 18, 
                  color: theme.palette.text.disabled,
                  cursor: 'help'
                }} 
              />
            </Tooltip>
          </Typography>

          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} md={4}>
              <PremiumFeatureCard
                icon={<PdfIcon />}
                title="PDF Upload"
                description="Upload and process PDF documents directly"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PremiumFeatureCard
                icon={<GoogleIcon />}
                title="Google Drive"
                description="Connect and import documents from Google Drive"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PremiumFeatureCard
                icon={<PasteIcon />}
                title="Direct Text"
                description="Paste or type content directly for processing"
              />
            </Grid>
          </Grid>

          <Button
            variant="outlined"
            fullWidth
            startIcon={<LockIcon />}
            sx={{
              py: 1.5,
              textTransform: 'none',
              fontWeight: 500,
              borderWidth: '1.5px',
              '&:hover': {
                borderWidth: '1.5px'
              }
            }}
          >
            Upgrade to Premium
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default DocumentUploadPage;