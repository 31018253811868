

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Dashboard from './dashboard/Dashboard';
import { useAuth } from './useAuth';
import LoadingScreen from './LoadingScreen';
import CalendarList from './dashboard/CalendarView';
import CalendarListLocal from './dashboard/CalendarListLocal';
import OAuth from './OAuth';
import OAuthCallback from './OAuthCallback';
import Navbar from './components/Navbar';
import AccessDenied from './accessDenied';
import  VoicebotDashboard  from './Voicebot/VoicebotDashboard';
import PhoneNumbersPage from './components/PhoneNumbers';
import CallsPage from './components/Calls';
import BillingPage from './Voicebot/Billing';
import AccountSettingsPage from './Voicebot/Account';
import VoiceBotSubscription from './Voicebot/InitialSubscription';
import DocumentUploadPage from './KnowledgeBasePages/DocumentUploadPage';

function App() {
  const { currentUser, isLoading, checkSubscriptionStatus, checkVoicebotSubscriptionStatus } = useAuth();
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [isVoiceSubscriptionActive, setVoiceSubscriptionActive] = useState(false);

  const [isSubscriptionChecked, setIsSubscriptionChecked] = useState(false);
  const [appLoading, setAppLoading] = useState(true);


  useEffect(() => {
    const checkSubscription = async () => {
      if (currentUser) {
        try {
          const isActive = await checkSubscriptionStatus(currentUser.uid);
          const isVoiceActive = await checkVoicebotSubscriptionStatus(currentUser.uid);
          setIsSubscriptionActive(isActive);
          setVoiceSubscriptionActive(isVoiceActive)
          setIsSubscriptionChecked(true);
          console.log('Subscription status:', isActive);
        } catch (error) {
          console.error('Error checking subscription status:', error);
          setIsSubscriptionChecked(true);
        } finally {
          setAppLoading(false); // Set appLoading to false after the initial subscription check completes
        }
      } else {
        setIsSubscriptionChecked(true);
        console.log('No current user');
        setAppLoading(false); // Set appLoading to false even when no current user is found
      }
    };
  
    if (isLoading) {
      // If user authentication is still loading, ensure the app is still in loading state
      setAppLoading(true);
    } else {
      checkSubscription();
    }
  }, [currentUser, isLoading, checkSubscriptionStatus]);
  

  if (isLoading || !isSubscriptionChecked) {
    return <LoadingScreen />;
  }

  return (
    <>
      {appLoading ? (
        <LoadingScreen />
      ) : (
        <BrowserRouter>
          {currentUser && <Navbar />}
          <Routes>
            <Route path="/login" element={currentUser ? <Navigate to="/" /> : <SignIn />} />
            <Route path="/register" element={currentUser ? <Navigate to="/" /> : <SignUp />} />
            <Route path="/" element={currentUser ? <Dashboard /> : <Navigate to="/login" />} />
            <Route path="/knowledgebase/upload" element={currentUser ? <DocumentUploadPage /> : <Navigate to="/login" />} />
            <Route
              path="/calendar"
              element={
                currentUser ? (
                  isSubscriptionActive ? (
                    <CalendarList />
                  ) : (
                    <AccessDenied />
                  )
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route path="/oauth" element={<OAuth />} />
            <Route path="/oauthcallback" element={<OAuthCallback />} />

            <Route path="/voicebot" element={currentUser ? (isVoiceSubscriptionActive ? (<VoicebotDashboard />) : (<Navigate to="/voice-bot" />)) : (<Navigate to="/login" />)} />
            <Route 
                path="/voicebot/numbers" 
                element={
                  currentUser ? (
                    isVoiceSubscriptionActive ? (
                      <PhoneNumbersPage />
                    ) : (
                      <Navigate to="/voice-bot" />
                    )
                  ) : (
                    <Navigate to="/login" />
                  )
                } 
              />
              <Route path="/voicebot/calls" element={currentUser ? (isVoiceSubscriptionActive ? (<CallsPage />) : (<Navigate to="/voice-bot" />)) : (<Navigate to="/login" />)}/>
               
                <Route 
                path="/voicebot/billing" 
                element={
                  currentUser ? (
                    isVoiceSubscriptionActive ? (
                      <BillingPage />
                    ) : (
                      <Navigate to="/voice-bot" />
                    )
                  ) : (
                    <Navigate to="/login" />
                  )
                } 
              />

              <Route 
                path="/voicebot/account" 
                element={
                  currentUser ? (
                    isVoiceSubscriptionActive ? (
                      <AccountSettingsPage />
                    ) : (
                      <Navigate to="/voice-bot" />
                    )
                  ) : (
                    <Navigate to="/login" />
                  )
                } 
              />

              {/* Subscription page - only accessible to authenticated users without an active subscription */}
              <Route 
                path="/voice-bot" 
                element={
                  currentUser ? (
                    isVoiceSubscriptionActive ? (
                      <Navigate to="/voicebot" />
                    ) : (
                      <VoiceBotSubscription />
                    )
                  ) : (
                    <Navigate to="/login" />
                  )
                } 
              />

            
            
            
            {/* <Route path="/voicebot" element={<VoicebotDashboard />} />
            <Route path="/voicebot/numbers" element={<PhoneNumbersPage />} />
            <Route path="/voicebot/calls" element={<CallsPage />} />
            <Route path="/voicebot/billing" element={<BillingPage />} />
            <Route path="/voicebot/account" element={<AccountSettingsPage />} />
            <Route path="/voice-bot" element={<VoiceBotSubscription />} /> */}





          </Routes>
        </BrowserRouter>
      )}
    </>
  );
  

 
}

export default App;